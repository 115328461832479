.nav {
    transition: all 0.1s linear;
    position: fixed;
    z-index: 2000;
    padding: 20px;
    width: 100%;
    background: #000;
    // top: 0;
    left: 0;
  }

  .intro_nav_logo{
    //   height: auto;
      width: 120px !important;
  }
  .scrollNav {
    transition: all 0.5s ease-in;
    z-index: 2000;
    background: #000000;
    width: 100%;
    border-bottom: 1px solid #000000;
    top: 0;
    left: 0;
    
    #navbar{
        li {
          display: inline;
          list-style-type: none;
          margin: 5px 10px;
          a{
              text-decoration: none;
              color: rgb(255, 255, 255);
          }
          
        }
        .intro_logo_section{
            display: inline;
            &img{
                margin-top: 10px;
            }
            // vertical-align: middle;
        }
    }
  }

  #navbar{
          li {
            display: inline;
            list-style-type: none;
            margin: 5px 10px;
            a{
                text-decoration: none;
                color:rgb(255, 255, 255);
            }
            
          }
          .intro_logo_section{
            display: none;
        }
      }